import { UserContextProvider } from "./context/UserContext";
import { ToastContextProvider } from "./context/ToastContext";
import { LoadingContextProvider } from "./context/LoadingContext";
import MainStackNavigator from "./navigators/MainStackNavigator";

import "./App.css";

function App() {
  return (
    <ToastContextProvider>
      <LoadingContextProvider>
        <UserContextProvider>
          <MainStackNavigator />
        </UserContextProvider>
      </LoadingContextProvider>
    </ToastContextProvider>
  );
}

export default App;
