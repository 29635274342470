/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { itemWidth } from "../../../styles/CarouselSlideStyles";
import ReactHtmlParser from "react-html-parser";
import "./AssessmentDescription.css";
import { Icon } from "@iconify/react";


const AssessmentCompletion = ({
  data: { description },
  onButtonClick,
  onBackClick,
  loading = false,
  signedUrlSponsorLogo,
}) => {
  // const systemFonts = ['Montserrat_400Regular'];
  // const tagsStyles = {
  //   p: {
  //     fontSize: 18,
  //     color: 'grey',
  //     textAlign: 'left',
  //     fontFamily: 'Montserrat_400Regular',
  //   },
  // };

  return (
    <>
      <button className={"back-button ghost-button"} onClick={()=> onBackClick()}>
        <Icon icon="lucide:arrow-left-circle" width="50" height="50" /> Back
      </button>
      <div className="desc-container">
        <div className="desc-wrapper">
          <h1 className="desc-head-complete">
            YOU HAVE SUCCESSFULLY COMPLETED THE ASSESSMENT
          </h1>
          <p className="description-text">{ReactHtmlParser(description)}</p>
          <div className="desc-button-wrapper">
            <button
              onClick={() => onButtonClick()}
              className={"main-button desc-button"}
            >
              Explore my career options
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentCompletion;
