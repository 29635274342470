/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Storage } from "aws-amplify";
import "./ResultDisplay.css";

const ResultDisplay = ({
  category,
  percent,
  categorysub,
  icon,
  signedUrlSponsorLogo,
  results,
  backgroundColour,
  iconBackgroundColour,
  index,
}) => {
  const [signedUrlIcon, setSignedUrlIcon] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAssets() {
      setSignedUrlIcon(await Storage.get(icon));
    }
    fetchAssets();
  }, []);

  return (
    <div
      className="category-item"
      onClick={
        () => {
          console.log('beforeNav',results, index)
          navigate("/catagories", {state: {
            results,
            resultIndex: index,
            signedUrlSponsorLogo,
          }}
          )}
        }
      style={{ backgroundColor: `${backgroundColour}` }}
    >
      <div className="bubble-wrapper">
        <div
          className="total-bubble"
          style={{
            backgroundColor: `${backgroundColour}`,
          }}
        >
          <p className="total-percent">{percent}%</p>
        </div>
      </div>

      <div className="career-cat">
        <p className="cat-main">{category}</p>
        <p className="cat-sub">{categorysub}</p>
      </div>

      <div className="icon-wrapper">
        <div className="icon"
          style={{
            backgroundColor: `${backgroundColour}`,
          }}
        >
          {signedUrlIcon !== null ? (
            <img className="icon-image"
              src={signedUrlIcon}
              alt={icon}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ResultDisplay;
