import React, { useState, useEffect, useRef } from "react";
import { Storage } from "aws-amplify";

import RelatedCareerPaths from "./RelatedCareerPaths";
import RelatedCareerPathModal from "./RelatedCareerPathModal";
import { Icon } from "@iconify/react";

import "./CareerPath.css";

const CareerPath = ({
  careerPath,
  liked,
  handleFavouriteCareer,
  colour,
  showLike,
  onCareerPathBack
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [modalVisible, setModalVisible] = useState(false);
  const [signedUrlVideo, setSignedUrlVideo] = useState(null);
  const [modalHeader, setModalHeader] = useState("");
  const [modalDescription, setModalDescription] = useState("");
  const videoRef = useRef(null);
  const [isPreloading, setIsPreloading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
  }, []);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  // Get career specific video
  useEffect(() => {
    async function fetchAssets() {
      setSignedUrlVideo(await Storage.get(careerPath.video));
    }
    fetchAssets();

    // Ensure video starts from beginning
    if (videoRef.current) {
      // videoRef.current.setPositionAsync(0);
    }
    setIsError(false);
  }, [careerPath]);

  // const handleError = (e) => {
  //   // eslint-disable-next-line no-console
  //   console.warn("Video error:", e);
  //   setIsError(true);
  // };

  return (
    <>
    { screenWidth < 900 &&

    <button
        className={"back-button mobile-button ghost-button"}
        onClick={() => {
          onCareerPathBack()
          console.log('clicked');
        }}
      >
        <Icon icon="lucide:arrow-left-circle" width="50" height="50" /> 
      </button>
    }
    <div className="path-wrapper">
      <div className="path-aligner">
          <div className="path-heading">
            <h1 style={{ color: `${colour}`,marginBottom : "10px"
 }}>
              {careerPath.name.toUpperCase()}
            </h1>
            {!!showLike && (
              <span className="liked-heart">
                {!!liked ? (
                  <Icon
                    style={{ color: `${colour}` }}
                    icon="iconoir:heart-solid"
                    width="40"
                    height="40"
                  />
                ) : (
                  <Icon
                    style={{ color: `${colour}` }}
                    icon="iconoir:heart"
                    width="40"
                    height="40"
                  />
                )}
              </span>
            )}
          </div>
          <div className="video-container">
            {/* { isPreloading && !isError && (<Spinner size="lg" color={colour} style={{ flex: 1, position: "absolute", top: "40%", left: "45%" }} />)} */}
            {/* {isError && (
              <p
                style={{
                  flex: 1,
                  position: "absolute",
                  top: "40%",
                  left: "40%",
                }}
              >
                Video failed to load
              </p>
            )} */}
            <video
              style={{ border: `8px solid ${colour}` }}
              ref={videoRef}
              // style={{ flex: 1 }}
              src={signedUrlVideo}
              controls
              autoPlay
            />
          </div>
          <div className="path-desc" style={{ border: `8px solid ${colour}` }}>
            <div className="path-text">
              <p>{careerPath.description}</p>
            </div>
          </div>
      </div>
      <div className="related-paths">
        <div>
          <RelatedCareerPaths
            relatedCareerPaths={careerPath.relatedCareersBridges.items.map(
              (item) => item.relatedCareer
            )}
            setModalVisible={setModalVisible}
            setModalHeader={setModalHeader}
            setModalDescription={setModalDescription}
            colour={colour}
          />
        </div>
      </div>

      <RelatedCareerPathModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        careerPath={modalHeader}
        description={modalDescription}
        colour={colour}
      />
    </div>

    </>

  );
};

export default CareerPath;
