/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      subline
      description
      colour
      icon
      careers {
        nextToken
      }
      questions {
        nextToken
      }
      video
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareer = /* GraphQL */ `
  query GetCareer($id: ID!) {
    getCareer(id: $id) {
      id
      name
      description
      categoryID
      archive
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      video
      relatedCareersBridges {
        nextToken
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const listCareers = /* GraphQL */ `
  query ListCareers(
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRelatedCareer = /* GraphQL */ `
  query GetRelatedCareer($id: ID!) {
    getRelatedCareer(id: $id) {
      id
      type
      name
      description
      version
      createdAt
      updatedAt
    }
  }
`;
export const listRelatedCareers = /* GraphQL */ `
  query ListRelatedCareers(
    $filter: ModelRelatedCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelatedCareers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      description
      categoryID
      type
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      set
      number
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRelatedCareersBridge = /* GraphQL */ `
  query GetRelatedCareersBridge($id: ID!) {
    getRelatedCareersBridge(id: $id) {
      id
      careerID
      relatedCareerID
      career {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
      relatedCareer {
        id
        type
        name
        description
        version
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRelatedCareersBridges = /* GraphQL */ `
  query ListRelatedCareersBridges(
    $filter: ModelRelatedCareersBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelatedCareersBridges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        careerID
        relatedCareerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudent = /* GraphQL */ `
  query GetStudent($id: ID!) {
    getStudent(id: $id) {
      id
      userID
      username
      name
      surname
      age
      email
      phone_number
      gradeID
      schoolID
      sponsors {
        nextToken
      }
      createdAt
      updatedAt
      grade {
        id
        name
        status
        createdAt
        updatedAt
      }
      school {
        id
        name
        status
        createdAt
        updatedAt
      }
    }
  }
`;
export const listStudents = /* GraphQL */ `
  query ListStudents(
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSponsorStudentBridge = /* GraphQL */ `
  query GetSponsorStudentBridge($id: ID!) {
    getSponsorStudentBridge(id: $id) {
      id
      sponsorID
      studentID
      code
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSponsorStudentBridges = /* GraphQL */ `
  query ListSponsorStudentBridges(
    $filter: ModelSponsorStudentBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStudentBridges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResults = /* GraphQL */ `
  query GetResults($id: ID!) {
    getResults(id: $id) {
      id
      studentID
      sponsorStudentBridgeID
      file
      progress
      expiryDate
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      sponsorStudentBridge {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      responses {
        nextToken
      }
      feedback {
        nextToken
      }
      resultCategories {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResults = /* GraphQL */ `
  query ListResults(
    $filter: ModelResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResultCategory = /* GraphQL */ `
  query GetResultCategory($id: ID!) {
    getResultCategory(id: $id) {
      id
      resultID
      score
      categoryID
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      category {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResultCategories = /* GraphQL */ `
  query ListResultCategories(
    $filter: ModelResultCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResultCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        score
        categoryID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedbackQuestion = /* GraphQL */ `
  query GetFeedbackQuestion($id: ID!) {
    getFeedbackQuestion(id: $id) {
      id
      description
      type
      number
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbackQuestions = /* GraphQL */ `
  query ListFeedbackQuestions(
    $filter: ModelFeedbackQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbackQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        type
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      resultID
      feedbackQuestionID
      feedback
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      feedbackQuestion {
        id
        description
        type
        number
        t
        version
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resultID
        feedbackQuestionID
        feedback
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      resultID
      questionID
      studentID
      sponsorStudentBridgeID
      response
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      question {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      student {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      sponsorStudentBridge {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        resultID
        questionID
        studentID
        sponsorStudentBridgeID
        response
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSponsorCode = /* GraphQL */ `
  query GetSponsorCode($id: ID!) {
    getSponsorCode(id: $id) {
      id
      sponsorID
      code
      createdAt
      updatedAt
      sponsor {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSponsorCodes = /* GraphQL */ `
  query ListSponsorCodes(
    $filter: ModelSponsorCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sponsorID
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFavouritedCareer = /* GraphQL */ `
  query GetFavouritedCareer($id: ID!) {
    getFavouritedCareer(id: $id) {
      id
      resultID
      careerID
      result {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      career {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFavouritedCareers = /* GraphQL */ `
  query ListFavouritedCareers(
    $filter: ModelFavouritedCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavouritedCareers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        careerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCategoriesByName = /* GraphQL */ `
  query ListCategoriesByName(
    $t: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriesByName(
      t: $t
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subline
        description
        colour
        icon
        video
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCareersByName = /* GraphQL */ `
  query ListCareersByName(
    $t: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareersByName(
      t: $t
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCareersByCategoryByName = /* GraphQL */ `
  query ListCareersByCategoryByName(
    $categoryID: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareersByCategoryByName(
      categoryID: $categoryID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        categoryID
        archive
        video
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRelatedCareersByName = /* GraphQL */ `
  query ListRelatedCareersByName(
    $type: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRelatedCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelatedCareersByName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        description
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRelatedCareersByVersion = /* GraphQL */ `
  query ListRelatedCareersByVersion(
    $type: String
    $version: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRelatedCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelatedCareersByVersion(
      type: $type
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        description
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsBySet = /* GraphQL */ `
  query ListQuestionsBySet(
    $t: String
    $set: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsBySet(
      t: $t
      set: $set
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsByNumber = /* GraphQL */ `
  query ListQuestionsByNumber(
    $t: String
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByNumber(
      t: $t
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsByDescription = /* GraphQL */ `
  query ListQuestionsByDescription(
    $t: String
    $description: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByDescription(
      t: $t
      description: $description
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsByVersion = /* GraphQL */ `
  query ListQuestionsByVersion(
    $t: String
    $version: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByVersion(
      t: $t
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsByCategoryID = /* GraphQL */ `
  query ListQuestionsByCategoryID(
    $categoryID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByCategoryID(
      categoryID: $categoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByCategoryBySet = /* GraphQL */ `
  query QuestionsByCategoryBySet(
    $categoryID: ID
    $set: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByCategoryBySet(
      categoryID: $categoryID
      set: $set
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByCategoryByNumber = /* GraphQL */ `
  query QuestionsByCategoryByNumber(
    $categoryID: ID
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByCategoryByNumber(
      categoryID: $categoryID
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsBySetByNumber = /* GraphQL */ `
  query ListQuestionsBySetByNumber(
    $set: Int
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsBySetByNumber(
      set: $set
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        categoryID
        type
        set
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listRelatedCareersBridgesByCareerId = /* GraphQL */ `
  query ListRelatedCareersBridgesByCareerId(
    $careerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRelatedCareersBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRelatedCareersBridgesByCareerId(
      careerID: $careerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        careerID
        relatedCareerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStudentsByGradeId = /* GraphQL */ `
  query ListStudentsByGradeId(
    $gradeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentsByGradeId(
      gradeID: $gradeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStudentsBySchoolId = /* GraphQL */ `
  query ListStudentsBySchoolId(
    $schoolID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentsBySchoolId(
      schoolID: $schoolID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listStudentsByUserId = /* GraphQL */ `
  query ListStudentsByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStudentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudentsByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        username
        name
        surname
        age
        email
        phone_number
        gradeID
        schoolID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSponsorStudentBridgesBySponsorId = /* GraphQL */ `
  query ListSponsorStudentBridgesBySponsorId(
    $sponsorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorStudentBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStudentBridgesBySponsorId(
      sponsorID: $sponsorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSponsorStudentBridgesByStudentId = /* GraphQL */ `
  query ListSponsorStudentBridgesByStudentId(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorStudentBridgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorStudentBridgesByStudentId(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorID
        studentID
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listResultsByStudentId = /* GraphQL */ `
  query ListResultsByStudentId(
    $studentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResultsByStudentId(
      studentID: $studentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        studentID
        sponsorStudentBridgeID
        file
        progress
        expiryDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listResultCategoriesByResultId = /* GraphQL */ `
  query ListResultCategoriesByResultId(
    $resultID: ID
    $score: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelResultCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResultCategoriesByResultId(
      resultID: $resultID
      score: $score
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        score
        categoryID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const feedbackQuestionsByNumber = /* GraphQL */ `
  query FeedbackQuestionsByNumber(
    $t: String
    $number: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackQuestionsByNumber(
      t: $t
      number: $number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        type
        number
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFeedbacksByResultId = /* GraphQL */ `
  query ListFeedbacksByResultId(
    $resultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacksByResultId(
      resultID: $resultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        feedbackQuestionID
        feedback
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bySponsorStudentBridge = /* GraphQL */ `
  query BySponsorStudentBridge(
    $sponsorStudentBridgeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySponsorStudentBridge(
      sponsorStudentBridgeID: $sponsorStudentBridgeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        questionID
        studentID
        sponsorStudentBridgeID
        response
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listResponsesByResultId = /* GraphQL */ `
  query ListResponsesByResultId(
    $resultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponsesByResultId(
      resultID: $resultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        questionID
        question {
          id
          description
          set
          categoryID
          category {
            id
            name
            subline
            description
            icon
            colour
            video
          }
        }
        studentID
        sponsorStudentBridgeID
        response
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSponsorCodesBySponsor = /* GraphQL */ `
  query ListSponsorCodesBySponsor(
    $sponsorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorCodesBySponsor(
      sponsorID: $sponsorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorID
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const byCode = /* GraphQL */ `
  query ByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorID
        code
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listFavouritedCareersByResult = /* GraphQL */ `
  query ListFavouritedCareersByResult(
    $resultID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFavouritedCareerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavouritedCareersByResult(
      resultID: $resultID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        resultID
        careerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGrade = /* GraphQL */ `
  query GetGrade($id: ID!) {
    getGrade(id: $id) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGrades = /* GraphQL */ `
  query ListGrades(
    $filter: ModelGradeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGrades(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gradeByName = /* GraphQL */ `
  query GradeByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelGradeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gradeByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSchool = /* GraphQL */ `
  query GetSchool($id: ID!) {
    getSchool(id: $id) {
      id
      name
      status
      students {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSchools = /* GraphQL */ `
  query ListSchools(
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const schoolByName = /* GraphQL */ `
  query SchoolByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schoolByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSponsor = /* GraphQL */ `
  query GetSponsor($id: ID!) {
    getSponsor(id: $id) {
      id
      name
      logo
      license_count
      code
      content
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      students {
        nextToken
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const listSponsors = /* GraphQL */ `
  query ListSponsors(
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sponsorByName = /* GraphQL */ `
  query SponsorByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sponsorByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        logo
        license_count
        code
        content
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCareerCentre = /* GraphQL */ `
  query GetCareerCentre($id: ID!) {
    getCareerCentre(id: $id) {
      id
      name
      description
      image
      address {
        addressLine1
        addressLine2
        suburb
        city
        province
      }
      contact {
        phoneNumber
        emailAddress
        websiteURL
      }
      t
      version
      createdAt
      updatedAt
    }
  }
`;
export const listCareerCentres = /* GraphQL */ `
  query ListCareerCentres(
    $filter: ModelCareerCentreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCareerCentres(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        image
        t
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
