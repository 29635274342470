import { Auth } from "aws-amplify";


export const isGuest = async () => {
  const credentials = await Auth.Credentials.getCredSource();
  if (credentials === "guest" || credentials === undefined) {
    return true;
  }
  return false;
};

