import React, { useState, useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { UserContext } from "../../../context/UserContext";
import { LoadingContext } from "../../../context/LoadingContext";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Icon } from "@iconify/react";


import { listFeedbackQuestions } from "../../../graphql/queries";
import { createFeedback } from "../../../graphql/mutations";
import "./FeedbackModal.css";


const FeedbackModal = ({
  modalVisible,
  setModalVisible,
  setSendResultsModalVisible,
}) => {
  ///////////////////////////////////////Variables & States ///////////////////////////////////
  const [user] = useContext(UserContext);
  const [loading, setLoading] = useContext(LoadingContext)
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [feedback, setFeedback] = useState({});

  const isMobile = window.innerWidth <= 470;

  const fetchFeedbackQuestions = async () => {
    try {
      const feedbackQuestionsData = await API.graphql(
        graphqlOperation(listFeedbackQuestions)
      );
      setFeedbackQuestions(
        feedbackQuestionsData.data.listFeedbackQuestions.items
      );
    } catch (err) {
      console.log("error fetching feedback questions", err);
    }
  };

  

  useEffect(() => {
    fetchFeedbackQuestions();
  }, []);


  const renderItem = (item, index) => {
    ////////////////////////////////////////////////Stars/////////////////////////////////////////////////////////////////////
    if (item.type === "RATING") {
      return (
        <div className="rating">
          <div className="rating-question-box">
            <p className="rating-text">{`${(index + 1).toString()}. ${
              item.description
            }`}</p>
          </div>
          <div className="rating-wrapper">
            <Rating
              name="hover-feedback"
              value={
                feedback[item.id] ? parseInt(feedback[item.id].feedback, 10) : 0
              }
              defaultValue={0}
              // getLabelText={getLabelText}
              onChange={(ev, rating) => {
                console.log(feedback);
                setFeedback({
                  ...feedback,
                  [item.id]: {
                    id: item.id,
                    question: item.description,
                    type: item.type,
                    feedback: rating,
                  },
                });
                // setValue(rating)
              }}
            
              emptyIcon={
                <StarIcon className="star-icon-empty" style={{ opacity: 0.55, fontSize: isMobile? "2.5rem": "50px"}} />
              }
              icon={<StarIcon className="star-icon-filled" style={{ fontSize: isMobile? "2.5rem": "50px" }} />}
            />
          </div>
        </div>
      );
    }
    //////////////////////////////////////////////// Thumbs /////////////////////////////////////////////////////////////////////

    if (item.type === "UPDOWN") {
      return (
        <div className="rating">
          <div className="rating-q-box">
            <p className="rating-text">{`${(index + 1).toString()}. ${
              item.description
            }`}</p>
          </div>
          <div className="thumb-wrapper">
            <button
              className="yes-no-button no-b"
              onClick={() => {
                setFeedback({
                  ...feedback,
                  [item.id]: {
                    id: item.id,
                    question: item.description,
                    type: item.type,
                    feedback: "false",
                  },
                });
              }}
             
            >
              {feedback[item.id]?.feedback === "false" ? (
                <Icon style={{stroke: "1px"}}
                icon="bi:hand-thumbs-down-fill"
                width="60"
                height="60"
                color="red"
              />
              ) : (
                <Icon
                  icon="bi:hand-thumbs-down"
                  width="60"
                  height="60"
                  strokeWidth="1"
                />
              )}
            </button>

            <button
              className="yes-no-button yes-b"
              onClick={() => {
                setFeedback({
                  ...feedback,
                  [item.id]: {
                    id: item.id,
                    question: item.description,
                    type: item.type,
                    feedback: "true",
                  },
                });
              }}
            >
              {feedback[item.id]?.feedback === "true" ? (
                <Icon
                  icon="bi:hand-thumbs-up-fill"
                  width="60"
                  height="60"
                  color="green"
                />
              ) : (
                <Icon icon="bi:hand-thumbs-up" width="60" height="60" />
              )}
            </button>
          </div>
        </div>
      );
    }

    ///////////////////////////////////////////////////////// Text /////////////////////////////////////////////////////////////////////
    return (
      <div className="rating">
        <div className="rating-question-box">
          <p className="rating-text">{`${(index + 1).toString()}. ${
            item.description
          }`}</p>
        </div>
        <input
          className="rating-input"
          value={
            feedback?.[item.id]?.feedback ? feedback[item.id].feedback : ""
          }
          placeholder="Enter your feedback here"
          onChange={(ev) => {
            setFeedback({
              ...feedback,
              [item.id]: {
                id: item.id,
                question: item.description,
                type: item.type,
                feedback: ev.target.value,
              },
            });
          }}
        />
      </div>
    );
  };

  const handleFeedback = async () => {
    setLoading(true)
    const sponsor = JSON.parse(await localStorage.getItem("sponsor"));

    // eslint-disable-next-line no-restricted-syntax
    for (const item of Object.values(feedback)) {
      // eslint-disable-next-line no-await-in-loop
      await API.graphql(
        graphqlOperation(createFeedback, {
          input: {
            resultID: user.student.resultID,
            feedbackQuestionID: item.id,
            feedback: item.feedback,
            sponsorID: sponsor.id,
            studentID: user.student.id,
            t: "Feedback",
            version: 0,
          },
        })
      );
    }
    setLoading(false)
    setModalVisible(false);
    setSendResultsModalVisible(true);
  };

  ////////////////////////////////// Main return ///////////////////////////////////////////////////////////

  return (
    <>
      {modalVisible && (
        <>
          <div className="modal-background"></div>
          <div className="modal-layout" >
            <div className="feedback-wrapper">
              <div
                title="Feedback Evaluation"
                className="feedback-content"
              >
                <img
                  className="logo-image"
                  src={require("../../../assets/cbcLogoFull.png")}
                  alt="logo"
                />
                <h3 className="feedback-sub-heading">
                  Your feedback is important to us.
                </h3>
                <p className="feedback-sub-text">
                  Please provide us with some feedback on the activity that you
                  have performed here today.
                </p>

                <div className="fb-question-container">
                  {feedbackQuestions.map((item, index) => (
                    <div key={item.id.toString()}>
                      {renderItem(item, index)}
                    </div>
                  ))}
                </div>
                <div className="fb-button-container">

                <button className="main-button"  onClick={() => handleFeedback()}>
                  Continue
                </button>
                <button className="ghost-button" onClick={() => setModalVisible(false)}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FeedbackModal;
