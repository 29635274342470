import React, { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";

import { LoadingContext } from "../../../context/LoadingContext";
import { UserContext } from "../../../context/UserContext";
import "./SendResultsModal.css";
import { ToastContext } from "../../../context/ToastContext";

// import { FormCard, GhostButton } from "../../../../../cbc-rn-app/src/components";
const SendResultsModal = ({
  navigation,
  modalVisible,
  setModalVisible,
  code,
  busyGenerating,
}) => {
  const [user, setUser] = useContext(UserContext);
  const [Toast, setToast] = useContext(ToastContext);

  const [loading, setLoading] = useContext(LoadingContext);
  const [printLoading, setPrintLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [printedCopy, setPrintedCopy] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setSent(false);
  }, []);

  const distributeStudentReport = async (printRequested) => {
    setLoading(true);
    await API.post("student", "/distributestudentreport", {
      body: {
        resultID: user.student.resultID,
        printed: printRequested,
      },
      headers: {},
    }).then((response) => {
      setSent(true);
      setLoading(false);
      setTimeout(() => {
        setModalVisible(false);
      }, 3000)

      //   const alertMessage = printRequested
      //     ? "A printed copy has been requested"
      //     : "Report sent";
      //   console.log(`${alertMessage}`, `${response.message}`);
      // })
      // .catch((error) => {
      //   // eslint-disable-next-line no-console
      //   console.warn("Distribute Student Report error", error);
      //   console.warn("Distribute Student Report error", error.response);
      //   console.log("An error ocurred", "Student report failed to send");
      // })
      // .then(() => {
      //   // Always
      //   setPrintLoading(false);
    }).catch((error)=> {
      setToast({
        showToast: true,
        messageType: "ERROR",
        toastMessage: `An error occurred while sending the report`,
      });
    })
  };

  return (
    <>
      {modalVisible && (
        <>
          <div className="modal-background"></div>
          <div className="modal-layout">
            <img
              className="sponsor-logo"
              src={require("../../../assets/cbcLogoFull.png")}
              alt="logo"
            />
            <div className="send-result-content">
              <h1 className="sr-heading">Important Notice</h1>
              <div className="sr-text-wrapper">
                <p>You will have access to your results for 1 year.</p>
                <p>
                  You can return from any other device if you download the app
                  and enter the voucher code that you used to start the
                  assessment.
                </p>
                <p>
                  Please keep your code in a safe place as you will use it as a
                  password to sign in:
                </p>
                <h2>{code}</h2>
              </div>
              <div className="sr-button-wrapper">
                <button
                  className={` main-button ${
                    sent ? "disabled-button" : "send-button"
                  }`}
                  disabled={sent}
                  onClick={() => {
                    if (busyGenerating) {
                      console.log(
                        "Generating report",
                        "Report is busy generating, please try again in a few minutes."
                      );
                    } else if (!sent) {
                      distributeStudentReport(false);
                    }
                  }}
                >
                  {
                    // eslint-disable-next-line no-nested-ternary
                    busyGenerating
                      ? "Generating report..."
                      : !sent
                        ? "Send results"
                        : "Sent!"
                  }
                </button>
                {/* <Button
          p={1}
          isLoading={printLoading}
          onPress={() => {
            if (!printedCopy) {
              distributeStudentReport(true);
            }
          }}
        >
          {!printedCopy ? "I prefer a printed copy" : "Printed copy requested!"}
        </Button> */}
                <button
                  className="main-button"
                  onClick={() => {
                    setModalVisible(false);
                    navigate("/");
                  }}
                >
                  Done
                </button>
                <button
                  className="ghost-button button-frame"
                  onClick={() => setModalVisible(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default SendResultsModal;
