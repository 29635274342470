/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef, useState, useContext, useEffect } from "react";
import "./AssessmentScreen.css";
// import { TouchableOpacity, Alert } from "react-native";
// import { Box, Image } from "native-base";
// import Carousel from "react-native-snap-carousel";
import { graphqlOperation, API  } from "aws-amplify";
import { sliderWidth, itemWidth } from "../../styles/CarouselSlideStyles";
import AssessmentSlide from "./components/AssessmentSlide";
import AssessmentDescription from "./components/AssessmentDescription";
import AssessmentCompletion from "./components/AssessmentCompletion";
// import { scrollInterpolator, animatedStyles } from "../../utils/carouselAnimations";

import { UserContext } from "../../context/UserContext";
import { LoadingContext } from "../../context/LoadingContext";

import { listResponsesByResultId } from "../../graphql/queries";
import { json, useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import { ToastContext } from "../../context/ToastContext";

const AssessmentScreen = () => {
  const [Toast, setToast] = useContext(ToastContext);
  const location = useLocation();   
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  // const [Toast, setToast]= useContext(ToastContext)
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useContext(LoadingContext);
  const [questions, setQuestions] = useState(location.state?.questions);
  const sponserLogo = location.state?.signedUrlSponsorLogo
  const swiperEl = document.querySelector("swiper-container");
  const swiperElRef = useRef(null);

  useEffect(() => {
    if (location.state === null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener("swiperprogress", (e) => {
      const [swiper, progress] = e.detail;
      setActiveIndex(progress.activeIndex + 1);
    });

  }, []);

  const handleAnswer = (index, response) => {
    const updatedQuestions = [...questions];
    
    updatedQuestions[index].response = response;
    setQuestions(updatedQuestions);
    handleCarouselScreen(index);

    API.post("student", "/response", {
      body: {
        response,
        resultID: user.student.resultID,
        questionID: questions[index].id,
        studentID: user.student.id,
        sponsorStudentBridgeID: user.student.sponsorStudentBridgeID,
      },
      headers: {},
    }).catch((error) => {
      // eslint-disable-next-line no-console
      setToast({
        showToast: true,
        messageType: "ERROR",
        toastMessage: `Error occurred when sending response`,
      });
      console.log(
        error.response?.data ? error.response.data : "An error ocurred"
      );
    });
  };

  const gotoPreviousSlide = () => {
    swiperEl.swiper.slidePrev();
  };

  const handleCarouselScreen = async (index, topCategories = {}) => {
    if (index === questions.length - 1) {
      return navigate("/results", { state: {
        results: topCategories,
        isNewStudent: true,}
      });

    }
    // swiperEl.swiper.allowTouchMove = true

    swiperEl.swiper.slideNext();
  };

  const calculateResults = async (index) => {
    setLoading(true);
    try {
      // Get all responses from this session
      const responses = await API.graphql(
        graphqlOperation(listResponsesByResultId, {
          resultID: user.student.resultID,
          limit: 200,
        })
        );
        
      // Foreach category, calculate the number of "Yes's"
      const results = {};
      responses.data.listResponsesByResultId.items.forEach((item) => {
        const { category } = item.question;

        if (!results?.[category.id]) {
          const outcome = {
            resultID: user.student.resultID,
            categoryID: category.id,
            count: 0, // Number of yes's
            total: 0, // Total number of questions in the category
            category: { ...category },
            score: 0, // Initial score
          };
          results[category.id] = outcome;
        }
        // eslint-disable-next-line no-plusplus
        results[category.id].total++;

        if (item.response === true) {
          // eslint-disable-next-line no-plusplus
          results[category.id].count++;
          // eslint-disable-next-line max-len
          results[category.id].score = (
            (results[category.id].count / results[category.id].total) *
            100
          ).toFixed(0);
        }
      });
      
      // Send data to Student/outcome
      const categories = Object.values(results);
      await API.post("student", "/outcome", {
        body: {
          resultID: user.student.resultID,
          categories,
          useResponse: false,
        },
        headers: {},
        timeout: 120000, // 2 minutes
      })
        .then(() => {
          const topCategories = categories
            .sort((a, b) => parseInt(b.score, 10) - parseInt(a.score, 10))
            .slice(0, 3);
          handleCarouselScreen(index, topCategories);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          setToast({
            showToast: true,
            messageType: "ERROR",
            toastMessage: 'An error ocurred, Could not save outcome'
          });
          console.log(
            error.response?.data ? error.response.data : "An error ocurred",
            "Could not save outcome"
          );
        })
        // always
        .then(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setToast({
        showToast: true,
        messageType: "ERROR",
        toastMessage: 'An error ocurred, Could not save outcome'
      });
      console.log(
        error.response?.data ? error.response.data : "An error ocurred",
        "Could not save outcome"
      );
      // eslint-disable-next-line no-console
      console.log(
        error.response?.data ? error.response.data : "An error ocurred",
        "Could not calculate results"
      );
    }
  };

  const renderItem = ({ slide, index }) => {
    // console.log("index", index, "item", slide);

    if (swiperEl) {
      swiperEl.swiper.allowTouchMove = false;
    }

    if (index === 0) {
      return (
        <AssessmentDescription
          data={slide}
          onButtonClick={() => handleCarouselScreen(index)}
        />
      );
    }

    if (index === questions.length - 1) {
      return (
        <AssessmentCompletion
          data={slide}
          loading={loading}
          onButtonClick={() => calculateResults(index)}
          onBackClick={() => gotoPreviousSlide()}
        />
      );
    }
    return (
      <AssessmentSlide
        active={true}
        data={slide}
        index={index}
        total={questions.length - 2}
        onButtonClick={(response) => handleAnswer(index, response)}
        onBackClick={() => gotoPreviousSlide()}
      />
    );
  };

  return (
    <>
      <div className="background-layout"></div>
      <swiper-container
        // cssMode: true
        ref={swiperElRef}
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={50}
        slidesPerView={1}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {questions?.map((slide, index) => (
          <swiper-slide key={index}>
            {renderItem({ slide, index })}
          </swiper-slide>
        ))}
      </swiper-container>
      <div className="logos" >
        <img className="sponsor-logo" src= {sponserLogo} alt="" />
        <img
            className="sponsor-logo"
            src={require('../../assets/cbcLogoFull.png')}
            alt="logo"
          />
      </div>
    </>
  );
};

export default AssessmentScreen;
