import React, { useState, useEffect } from "react";
import "./HomeScreen.css";

import AssessmentAuthModal from "../Assessment/components/AssessmentAuthModal";


const HomeScreen = () => {
  const [assessmentAuthModalVisible, setAssessmentAuthModalVisible] =
    useState(false);
    

  useEffect(()=> setAssessmentAuthModalVisible(true), [])
  return (
    <div className="home-page-wrapper">
      <div className="background-layout">
        </div>
       
        <div className="footer">
          <div>All copyright reserved</div>
          <img
            src={require('../../assets/cbcLogoFull.png')}
            alt="logo"
            width={150}
            style={{
              maxWidth: "100%",
              alignSelf: "center",
            }}
          />
          <div>(ver: 1.0.2)</div>
        </div>
     
        <>
       
            <AssessmentAuthModal
              modalVisible={assessmentAuthModalVisible}
              setModalVisible={setAssessmentAuthModalVisible}
              />
      
              </>
    </div>
  );
};

export default HomeScreen;
