/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCog } from "@fortawesome/free-solid-svg-icons";

import { UserContext } from "../context/UserContext";

import AssessmentScreen from "../screens/Assessment/AssessmentScreen";
import HomeScreen from "../screens/Home/HomeScreen";
import ResultsScreen from "../screens/Results/ResultsScreen";
import CategoryCarousel from "../screens/Categories/CategoryCarousel";
import CareerPathsScreen from "../screens/CareerPaths/CareerPathsScreen";


const MainStackNavigator = () => {
  const [user, setUser] = useContext(UserContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/assessment" element={<AssessmentScreen />} />
        <Route path="/results" element={<ResultsScreen />}/>
        <Route path="/catagories" element={<CategoryCarousel />}/>
        <Route path="/careerpaths" element={<CareerPathsScreen />}/>

      </Routes>
    </BrowserRouter>
  );
};

export default MainStackNavigator;

/*  <Route exact path="/">
          <div style={{ backgroundColor: "white" }}>
            <HeaderComponent />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to="/settings">
                <FontAwesomeIcon icon={faCog} size="lg" color="#717171" />
              </Link>
            </div>
            <HomeScreen />
          </div>
        </Route>
        <Route path="/login" component={LoginScreen} />
       {/*  <Route path="/signup" component={SignUpScreen} /> */
/*   <Route path="/forgotpassword" component={ForgotPasswordScreen} />
        <Route path="/resetpassword" component={ResetPasswordScreen} />
        <Route path="/categories" component={CategoryCarousel} />
        <Route path="/careerpaths" component={CareerPathsScreen} />
        <Route path="/careerpathmobile" component={CareerPathMobileScreen} />
        <Route path="/about" component={AboutScreen} />
        <Route path="/findacentre" component={FindACentreScreen} />
        <Route path="/careercentermobile" component={CareerCentreMobileScreen} />
        <Route path="/settings" component={SettingsScreen} />
        <Route path="/results" component={ResultsScreen} />*/
