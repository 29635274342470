import React, { useEffect, useState, useContext, useRef } from "react";
import { API } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";


import "./ResultsScreen.css";

import ResultDisplay from "./components/ResultDisplay";
import FeedbackModal from "./components/FeedbackModal";
import SendResultsModal from "./components/SendResultsModal";
import { ToastContext } from "../../context/ToastContext";


const ResultsScreen = () => {
  const location = useLocation();

  const { results, signedUrlSponsorLogo, isNewStudent } = location.state;
  const [user] = useContext(UserContext);
  const [FeedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [sendResultsModalVisible, setSendResultsModalVisible] = useState(false);
  const [code, setCode] = useState("");
  const [busyGenerating, setBusyGenerating] = useState(false);
  const navigate = useNavigate();
  const [Toast, setToast] = useContext(ToastContext);



  useEffect(() => {
    async function fetchStudentCode() {
      setCode(await localStorage.getItem("student_code"));
    }
    fetchStudentCode();
  });

  const generateStudentReport = async () => {
    setBusyGenerating(true);
    await API.post("student", "/student/generateStudentReport", {
      body: {
        resultID: user.student.resultID,
      },
      headers: {},
      timeout: 120000, // 2 minutes
    })
      .then(() => {
        console.log("Report successfully generated");
        setToast({
          showToast: true,
          messageType: "SUCCESS",
          toastMessage: `Report successfully generated`,
        });
      })
      .catch((error) => {
        const message = error.
        setToast({
          showToast: true,
          messageType: "ERROR",
          toastMessage: `An error occurred, please check form entries and try again`,
        });
        // eslint-disable-next-line no-console
        console.log(
          error,
          "An error ocurred",
          "Student report failed to generate"
        );
      })
      .then(() => {
        // always
        setBusyGenerating(false);
      });
  };

  return (
    <div className="rd-wrapper">
      <div className="results-heading wrapper">
        <h1 className="result-heading">
          Select one of the categories below to view career paths
        </h1>
      </div>
      <div className="result-container">
        {results.map((result, index) => (
          <ResultDisplay
            // eslint-disable-next-line react/no-array-index-key
            className="result-display"
            key={`result-${index}`}
            index={index}
            category={result.category.name}
            percent={result.score}
            categorysub={result.category.subline}
            icon={result.category.icon}
            // navigation={navigation}
            navigate="Categories"
            signedUrlSponsorLogo={signedUrlSponsorLogo}
            results={results}
            backgroundColour={result.category.colour}
            iconBackgroundColour="white"
          />
        ))}
      </div>
      <div className="page-footer">
        <div className="pf-content">
          <button
            className="send-results-button main-button"
            onClick={() => {
              generateStudentReport();
              if (isNewStudent) {
                setFeedbackModalVisible(true);
              } else {
                setSendResultsModalVisible(true);
              }
            }}
          >
            Send my results
          </button>

          <img
            className="footer-logo"
            src={require("./../../assets/cbcLogoFull.png")}
            alt="logo"
          />
        </div>

        <FeedbackModal
          modalVisible={FeedbackModalVisible}
          setModalVisible={setFeedbackModalVisible}
          setSendResultsModalVisible={setSendResultsModalVisible}
        />

        <SendResultsModal
          modalVisible={sendResultsModalVisible}
          setModalVisible={setSendResultsModalVisible}
          code={code}
          busyGenerating={busyGenerating}
        />
      </div>
    </div>
  );
};

export default ResultsScreen;
