import React, { useState, useContext, useLayoutEffect, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { UserContext } from "../../context/UserContext";
import CareerPath from "./components/CareerPath";
import { LoadingContext } from "../../context/LoadingContext";
import { listCareers } from "../../graphql/extended/queries";
import { listFavouritedCareersByResult } from "../../graphql/queries";
import {
  createFavouritedCareer,
  deleteFavouritedCareer,
} from "../../graphql/mutations";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

import "./CareerPathsScreen.css";

const CareerPathsScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location);
  const { career } = location.state;
  const careerColour = career.category.colour;

  const [user] = useContext(UserContext);
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [careers, setCareers] = useState([]);
  const [liked, setLiked] = useState(false);
  // const [likedCareers, setLikedCareers] = useState([]);
  const [showCareerPath, setShowCareerPath] = useState(false);
  const [loadingList, setLoadingList] = useContext(LoadingContext);

  useEffect(() => {
    fetchCareers();
  }, []);

  const fetchCareers = async () => {
    setLoadingList(true);
    try {
      const filter = {
        categoryID: {
          eq: career.categoryID,
        },
      };
      const careersData = await API.graphql({
        query: listCareers,
        // eslint-disable-next-line no-undef
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { filter },
      });
      setCareers(careersData.data.listCareers.items);
      // setSelectedCareer(careersData.data.listCareers.items[0]);
      setLoadingList(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn("error fetching careers", err);
      setLoadingList(false);
    }
  };

  // useEffect(() => {
  //   if (selectedCareer !== null) {
  //     if (likedCareers.length <= 0) {
  //       // fetchLikedCareers();
  //     }
  //     setLiked(!!likedCareers.includes(selectedCareer.id));
  //   }
  // }, [selectedCareer]);

  const onCareerPathSelect = (careerPath) => {
    setShowCareerPath(true);
    return setSelectedCareer(careerPath);
  };

  const onCareerPathBack = () => {
    setShowCareerPath(false);
    return setSelectedCareer(null);
  };
  //////////////////////////////Careers List////////////////////////
  const renderList = () => (
    <>
      <div className="background-layout"></div>
      <div className="career-path-page">
        <div className="career-path-wrapper">
          <div className="list-wrapper">
            {careers.map((career) => (
              <>
                {selectedCareer?.id === career.id ? (
                  <div
                    className="career-item-wrapper"
                    onClick={() => onCareerPathSelect(career)}
                    style={{
                      border: `3px solid ${careerColour}`,
                      backgroundColor: `${careerColour}`,
                    }}
                  >
                    <p className="career-item" style={{ color: `white` }}>
                      {career.name}
                    </p>
                  </div>
                ) : (
                  <div
                    className="career-item-wrapper"
                    onClick={() => onCareerPathSelect(career)}
                    style={{ border: `3px solid ${careerColour}` }}
                  >
                    <p
                      className="career-item"
                      style={{ color: `${careerColour}` }}
                    >
                      {career.name}
                    </p>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>

        {selectedCareer === null ? null : (
          <div className="career-desc">
            <CareerPath
              onCareerPathBack={onCareerPathBack}
              careerPath={selectedCareer}
              user={user}
              liked={liked}
              // handleFavouriteCareer={handleFavouriteCareer}
              colour={career.category.colour}
            />
          </div>
        )}
      </div>
    </>
  );

  //////////////////////////////Careers List////////////////////////

  const renderView = () => {
    return renderList();
  };

  return (
    <div>
      <button
        className={"back-button ghost-button"}
        onClick={() => {
          navigate(-1);
        }}
      >
        <Icon icon="lucide:arrow-left-circle" width="50" height="50" /> Back
      </button>

      {renderView(showCareerPath)}
    </div>
  );
};

export default CareerPathsScreen;
