/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React,{ useState, createContext, useEffect } from "react";

export const UserContext = createContext();

const defaultUser = {
  id: null,
  name: "",
  email: "",
};

export const UserContextProvider = (props) => {
  const [user, setUser] = useState({defaultUser})  
  useEffect(() => {
    
    const storedUser = JSON.parse(localStorage.getItem("user_context"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      localStorage.setItem("user_context", JSON.stringify(user));
    }
  }, [user]);

  return (
    <UserContext.Provider value={[user, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};