const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

// Define the wp function as requested
const wp = (percentage) => {
  const value = (percentage * viewportWidth) / 100;
  return Math.round(value);
};

// Calculate slideHeight, slideWidth, itemHorizontalMargin, sliderWidth, and itemWidth
export const slideHeight = viewportHeight * 0.6;
const slideWidth = wp(75);
const itemHorizontalMargin = wp(2);

export const sliderWidth = viewportWidth;
export const itemWidth = slideWidth + itemHorizontalMargin * 2;