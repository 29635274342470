/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, createContext, useEffect } from "react";
import './ToastContext.css'


export const ToastContext = createContext();

const defaultToast = {
    showToast: false,
    messageType: 'SUCCESS',
    toastMessage: 'This is a test message'
}


export const ToastContextProvider = (props) => {
    const [Toast, setToast] = useState(defaultToast);
    useEffect(()=> {
        if(Toast?.showToast){
            setTimeout(()=> { 
                setToast((prevToast) => ({ ...prevToast, showToast: false }));
            },8000)
        }
    },[Toast.showToast, setToast])
    
  return (
    <ToastContext.Provider value={[Toast, setToast]}>
      {Toast.showToast && (
        <div className="toast-wrapper">
          <div className={`toast-message`} onClick={() => setToast({showToast: false})} style={{ backgroundColor: Toast.messageType === 'SUCCESS' ? 'green' : 'red' }}>
            <p>{Toast.toastMessage}</p>
            <span className="toast-close" onClick={() => setToast({showToast: false})}>X</span>
          </div>
        </div>
      )}
      {props.children}
    </ToastContext.Provider>
  );
};