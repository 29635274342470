/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useContext } from "react";
import { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import { isGuest } from "../../../utils/utilites";
import "./AssessmentAuthModal.css";
import { Icon } from "@iconify/react";

// import { FormCard, GhostButton, FormInput, FormMultiSelect } from "../../../components";
// import ModalView from "../../../components/ModalView";
import { UserContext } from "../../../context/UserContext";
import { LoadingContext } from "../../../context/LoadingContext";

import {
  listResultsByStudentId,
  listSchools,
  listGrades,
} from "../../../graphql/queries";
import {
  listSponsorStudentBridgesByStudentId,
  listResultCategoriesByResultId,
  maxQuestionSet,
  listQuestions,
  listResponsesByResultId,
} from "../../../graphql/extended/queries";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../../context/ToastContext";

// eslint-disable-next-line react/prop-types
const AssessmentAuthModal = ({ navigation, modalVisible, setModalVisible }) => {
  const navigate = useNavigate();
  const [userState, setUser] = useContext(UserContext);
  const [loading, setLoading] = useContext(LoadingContext);
  const [Toast, setToast] = useContext(ToastContext);
  const [isNewStudent, setIsNewStudent] = useState(true);
  const [activeForm, setActiveForm] = useState("login");
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  // const [loading, setLoading] = useState(false);
  const [schools, setSchools] = useState([]);
  const [schoolSearchTerm, setSchoolSearchTerm] = useState("");
  const [grades, setGrades] = useState([]);
  //   const [products, setProducts] = useState([]);
  //   const [busyFetchingProducts, setBusyFetchingProducts] = useState(true);

  useEffect(() => {
    setFormData({ ...formData, code: "" });
    // console.log(formData);
  }, []);

  const validateForm = () => {
    let error = {};

    if (formData.firstname === undefined || formData.firstname === "") {
      error = { ...error, firstname: "Name is required" };
    }

    if(formData.firstname.includes(' ')){
      error = { ...error, firstname: "Name cannot contain a space character" };
    }

    if (formData.surname === undefined || formData.surname === "") {
      error = { ...error, surname: "Surname is required" };
    }
    if (
      formData.age === undefined ||
      formData.age === "" ||
      isNaN(+formData.age)
    ) {
      error = { ...error, age: "Age is required" };
    }
    if (formData.grade === undefined || formData.grade === "") {
      error = { ...error, grade: "Grade is required" };
    }
    if (formData.school === undefined || formData.school === "") {
      error = { ...error, school: "School is required" };
    }
    // (formData.phone_number === undefined || formData.phone_number === "")
    if (formData.email === undefined || formData.email === "")
     {
      error = {
        ...error,
        email: "email is required",
      };
    }

    setErrors(error);
    console.log(error);
    if (Object.keys(error).length === 0) {
      return true;
    }
    setLoading(false);
    return false;
  };

  const validateStudentSignup = async () => {
    setLoading(true);
    if (validateForm()) {
      const code = await localStorage.getItem("student_code");
      const sponsor = JSON.parse(await localStorage.getItem("sponsor"));

      await API.post("student", "/signup", {
        body: {
          firstname: formData.firstname,
          surname: formData.surname,
          age: formData.age,
          grade: formData.grade,
          school: formData.school,
          email: formData.email,
          phone_number: formData?.phone_number
            ? formData.phone_number.replace(/^0/, "+27")
            : "",
          code,
          sponsor: sponsor.id,
          isNewStudent,
          studentID: userState?.student?.id ? userState.student.id : null,
        },
        headers: {},
        timeout: 60000, // 1 minute
      })
        .then(async (response) => {
          setToast({
            showToast: true,
            messageType: "SUCCESS",
            toastMessage: `sign up successful`,
          });
          setUser({
            ...userState,
            student: { ...userState.student, ...response },
          });

          // Authenticate new user
          // eslint-disable-next-line no-unused-vars
          const userData = await Auth.signIn(
            response.username,
            response.password
          )
            .then((user) => {
              if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                Auth.completeNewPassword(
                  user, // the Cognito User Object
                  response.password // the new password
                  // eslint-disable-next-line no-shadow, no-unused-vars
                )
                  .then((user) => {
                    startAssessment();
                  })
                  .catch((e) => {
                    // eslint-disable-next-line no-console
                    console.log(
                      e,
                      "Error signing in",
                      "Failed to authticate user"
                    );
                  });
              }
            })
            .catch((e) => {
              // eslint-disable-next-line no-console
              console.log(e, "Error signing in", "Incorrect password supplied");
            });
        })
        .catch((error) => {
          setLoading(false);
          // eslint-disable-next-line no-console
          console.log("ERROR", error.response.data);
          const message = error.response.data.statusCode === 400? error.response.data.message : 'An error occurred, please check form entries and try again'
          setToast({
            showToast: true,
            messageType: "ERROR",
            toastMessage: message,
          });
        });
    }
  };

  const fetchSchools = async () => {
    const filter = formData.school
      ? { filter: { name: { contains: schoolSearchTerm } } }
      : {};
    const schoolsData = await API.graphql({
      query: listSchools,
      variables: {
        limit: 1000,
        ...filter,
      },
      authMode: (await isGuest()) ? "AWS_IAM" : "AMAZON_COGNITO_USER_POOLS",
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.log("Fetch schools error", e);
      setToast({
        showToast: true,
        messageType: "ERROR",
        toastMessage: `Error occurred when fetching schools`,
      });
    });

    const aSchools = schoolsData.data.listSchools.items.map((item) => ({
      name: item.name,
      id: item.name,
    }));
    const uniqueSchools = [];
    aSchools.filter((item) => {
      const i = uniqueSchools.findIndex((x) => x.name === item.name);
      if (item.name === "None") {
        uniqueSchools.unshift({ id: item.id, name: item.name });
      } else if (i <= -1) {
        uniqueSchools.push({ id: item.id, name: item.name });
      }
      return null;
    });
    setSchools(uniqueSchools);
  };

  useEffect(() => {
    if (activeForm === "signup") {
      fetchSchools();
    }
  }, [formData.school, activeForm]);

  const fetchGrades = async () => {
    const gradesData = await API.graphql({
      query: listGrades,
      authMode: (await isGuest()) ? "AWS_IAM" : "AMAZON_COGNITO_USER_POOLS",
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.log("Fetch grades error", e);
      setToast({
        showToast: true,
        messageType: "ERROR",
        toastMessage: `Error occurred when fetching grades`,
      });
    });
    const sortedGrades = gradesData.data.listGrades.items
      .map((item) => ({ id: item.name, name: item.name }))
      .sort((a, b) =>
        parseInt(a.name, 10) > parseInt(b.name, 10) || b.name === "None"
          ? 1
          : -1
      );
    setGrades(sortedGrades);
  };

  useEffect(() => {
    if (activeForm === "signup") {
      fetchGrades();
    }
  }, [activeForm]);

  const fetchQuestions = async (questionSet = "", responses = []) => {
    try {
      if (questionSet === "") {
        // Get random set
        const maxSetData = await API.graphql(graphqlOperation(maxQuestionSet));
        const maxSet = maxSetData.data.listQuestionsBySet.items[0].set;
        // eslint-disable-next-line no-param-reassign
        questionSet = Math.floor(Math.random() * maxSet + 1);
      }
      setLoading(true);
      // Get all questions where set === questionSet
      const questionsData = await API.graphql(
        graphqlOperation(listQuestions, {
          limit: 1000,
          filter: { set: { eq: questionSet } },
        })
      );
      const questions = questionsData.data.listQuestions.items;

      const allCategoryQuestions = [];

      // Add responses to previous questions before unanswered questions
      responses.forEach((response) => {
        const index = questions.findIndex(
          (question) => question.id === response.questionID
        );
        questions[index] = {
          ...questions[index],
          response: response.response ? "1" : "0",
        };
        allCategoryQuestions.push(questions[index]);
        questions.splice(index, 1);
      });

      // Choose random question && disable its category
      if (questions.length > 0) {
        // Only do this if there are still some unanswered questions
        let randomIndex = Math.floor(Math.random() * questions.length);
        let currentCategory = questions[randomIndex].categoryID;
        allCategoryQuestions.push(questions[randomIndex]);
        questions.splice(randomIndex, 1);

        while (questions.length !== 0) {
          randomIndex = Math.floor(Math.random() * questions.length);
          if (questions[randomIndex].categoryID !== currentCategory) {
            currentCategory = questions[randomIndex].categoryID;
            allCategoryQuestions.push(questions[randomIndex]);
            questions.splice(randomIndex, 1);
          } else if (questions.length === 1) {
            allCategoryQuestions.push(questions[randomIndex]);
            questions.splice(randomIndex, 1);
          }
        }
      }

      let completeQuestionSet = [];
      completeQuestionSet.push({
        description:
          "<p>Your journey at the Careers by Colour app starts with an assessment of your interests. Complete the electronic self-assessment questionnaire to create your personal interest profile. Your profile will match you to one or more of the six colour-coded career categories.<br/><br/><strong>The career categories are:</strong><br/> <span class='green'>The Do-er</span>, <span class='red'>The Thinker</span>, <span class='purple'>The Creator</span>, <span class='blue'>The Helper</span>, <span class='orange'>The Persuader</span>, <span class='yellow'>The Organizer</span>.</p>",
      });
      completeQuestionSet = completeQuestionSet.concat(allCategoryQuestions);
      completeQuestionSet.push({
        description:
          "<p>Well done! If you are fully satisfied with your answers you can proceed to explore your career options.<br/><br/>Please note that once you proceed you will not be able to change the answers on your questionnaire.</p>",
      });

      setLoading(false)
      return completeQuestionSet;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
      setLoading(false)
      setToast({
        showToast: true,
        messageType: "ERROR",
        toastMessage: `Error occurred when fetching questions`,
      });

      return [];
    }
  };

  const startAssessment = async (set = "", responses = []) => {
    const questions = await fetchQuestions(set, responses);

    if (questions.length <= 2) {
      // Include start and end Assessment Descriptions
      console.log("Could not fetch questions", "Please try again");
      return;
    }

    const signedUrlSponsorLogo = await localStorage.getItem("sponsor_logo");
    navigate("/assessment", { state: { questions, signedUrlSponsorLogo } });

    setActiveForm("login");
    setModalVisible(false);
    setLoading(false);
  };

  const handleSponsorValidateCode = async () => {
    setLoading(true);
    if (formData.code === undefined || formData.code === "") {
      setErrors({ code: "voucher code is required" });
      setLoading(false);
      return;
    }

    await API.post("sponsor", "/validatecode", {
      body: {
        code: formData.code.target.value,
      },
      headers: { "Content-Type": "application/json" },
    })
      .then(async (response) => {
        await localStorage.setItem("student_code", response.student_code);
        await localStorage.setItem("sponsor", JSON.stringify(response.sponsor));

        const sponsorLogo = await Storage.get(response.sponsor.logo);
        await localStorage.setItem("sponsor_logo", sponsorLogo);

        setIsNewStudent(true);
        console.log(response);
        setToast({
          showToast: true,
          messageType: "SUCCESS",
          toastMessage: `Welcome ${
            response.student.name ? response.student.name : "student"
          }`,
        });
        if (response?.studentID) {
          setLoading(true);

          // Log student in
          // eslint-disable-next-line no-unused-vars
          const user = await Auth.signIn(
            response.student.username,
            response.student_code
          ).catch((e) => {
            // eslint-disable-next-line no-console
            console.log(e, "Error signing in", "Incorrect password supplied");
          });

          // Get resultID
          const resultData = await API.graphql(
            graphqlOperation(listResultsByStudentId, {
              studentID: response.studentID,
            })
          );
          const resultID = resultData.data.listResultsByStudentId.items[0].id;

          // Get sponsorStudentBridgeID
          const sponsorData = await API.graphql(
            graphqlOperation(listSponsorStudentBridgesByStudentId, {
              studentID: response.studentID,
            })
          );
          // eslint-disable-next-line max-len
          const sponsorStudentBridgeID =
            sponsorData.data.listSponsorStudentBridgesByStudentId.items[0].id;

          setIsNewStudent(false); // Need student to supply password

          // eslint-disable-next-line max-len
          setUser({
            ...userState,
            student: { ...response.student, resultID, sponsorStudentBridgeID },
          });

          // Determine if student has completed or was busy with assessment
          const resultCategoriesData = await API.graphql(
            graphqlOperation(listResultCategoriesByResultId, { resultID })
          );
          const resultCategories =
            resultCategoriesData.data.listResultCategoriesByResultId.items;

          // Assessment was not completed
          if (resultCategories.length <= 0) {
            const responsesData = await API.graphql(
              graphqlOperation(listResponsesByResultId, { resultID })
            );
            const responses = responsesData.data.listResponsesByResultId.items;
            console.log(responses);
            // Assessment has no responses
            if (responses.length <= 0) {
              startAssessment();
            } else {
              const questionSet = responses[0].question.set;
              startAssessment(questionSet, responses);
            }
          } else {
            const resultCategoryData = await API.graphql(
              graphqlOperation(listResultCategoriesByResultId, { resultID })
            );
            const categories =
              resultCategoryData.data.listResultCategoriesByResultId.items;
            const topCategories = categories.slice(0, 3);

            const signedUrlSponsorLogo =
              await localStorage.getItem("sponsor_logo");
            navigate("results", {
              state: {
                results: topCategories,
                isNewStudent: false,
                signedUrlSponsorLogo,
              },
            });
          }

          setLoading(false);
          setActiveForm("login");
          setModalVisible(false);
        } else {
          setActiveForm("signup");
        }

        setFormData({});
        setErrors({});
      })
      .catch((error) => {
        setToast({
          showToast: true,
          messageType: "ERROR",
          toastMessage: `Code Invalid: the sponsor code is case-sensitive. 
        Your input should look exactly the same as the sponsor code you have received`,
        });

        // eslint-disable-next-line no-console

        console.log(
          "here",
          error
          // error.response?.data ? error.response.data : "An error ocurred",
          // "Please note: the sponsor code is case-sensitive. Your input should look exactly the same as the sponsor code you have received."
        );
      })
      // always
      .then(() => {
        setLoading(false);
      });
  };

  const renderItem = ({ item }) => {
    if (item === "login") {
      return (
        <div className="code-wrapper">
          <img
            className="logo-image"
            src={require("../../../assets/cbcLogoFull.png")}
            alt="logo"
          />
          <h1 className="code-head">VOUCHER</h1>
          <input
            placeholder="Enter your code here"
            icon="lock"
            onChange={(value) => {
              setFormData({ ...formData, code: value });
            }}
            // value={formData?.code ? `${formData.code}` : ""}
          />
          {errors.code && <div className="error">{errors.code}</div>}
          <button className="main-button" onClick={handleSponsorValidateCode}>
            Continue
          </button>
          <div>
            <a
              href="https://careersbycolour.co.za/contact/"
              className="get-voucher-route"
            >
              <p className="no-code">I don't have a code?</p>
            </a>
          </div>
        </div>
      );
    } else if (item === "signup") {
      return (
        <div className="sign-up-wrapper">
          <img
            className="logo-image"
            src={require("../../../assets/cbcLogoFull.png")}
            alt="logo"
          />
          <form className="sign-up-form">
            <div className="form-wrapper">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                icon="user"
                value={formData?.firstname || ""}
                onChange={(value) => {
                  const fieldVal = value.target.value;
                  setFormData({ ...formData, firstname: fieldVal });
                }}
              />
              {errors.firstname && (
                <div className="error">{errors.firstname}</div>
              )}

              <label htmlFor="surname">Surname</label>
              <input
                id="surname"
                icon="user"
                onChange={(value) => {
                  const fieldVal = value.target.value;
                  setFormData({ ...formData, surname: fieldVal });
                }}
              />
              {errors.surname && <div className="error">{errors.surname}</div>}

              <label htmlFor="age">Age</label>
              <input
                id="age"
                icon="birthday-cake"
                value={formData?.age ? formData.age.toString() : ""}
                onChange={(value) => {
                  const fieldVal = value.target.value;
                  setFormData({ ...formData, age: fieldVal });
                }}
              />
              {errors.age && <div className="error">{errors.age}</div>}

              <label htmlFor="grade">Grade</label>
              <select
                id="grade"
                defaultValue={"default"}
                value={formData?.grade}
                onChange={(value) => {
                  const fieldVal = value.target.value;
                  setFormData({ ...formData, grade: fieldVal });
                }}
              >
                <option disabled value="default">
                  Select your grade
                </option>
                {grades.map((grade, index) => (
                  <option key={index} value={grade.name}>
                    {grade.name}
                  </option>
                ))}
              </select>
              {errors.grade && <div className="error">{errors.grade}</div>}

              <label htmlFor="email">Email</label>
              <input
                id="email"
                icon="envelope"
                value={formData?.email || ""}
                onChange={(value) => {
                  const fieldVal = value.target.value;
                  setFormData({ ...formData, email: fieldVal });
                }}
              />
              {errors.email && <div className="error">{errors.email}</div>}

              <label htmlFor="phoneNumber">Phone number</label>
              <input
                id="phoneNumber"
                icon="phone-alt"
                value={formData?.phone_number || ""}
                onChange={(value) => {
                  const fieldVal = value.target.value;
                  setFormData({ ...formData, phone_number: fieldVal });
                }}
              />
              {errors.phone_number && (
                <div className="error">{errors.phone_number}</div>
              )}

              <label htmlFor="school">School</label>
              <select
                id="school"
                value={formData?.school}
                defaultValue={"default"}
                onChange={(value) => {
                  const fieldVal = value.target.value;
                  setFormData({ ...formData, school: fieldVal });
                }}
              >
                <option disabled value="default">
                  Select your school
                </option>
                {schools.map((school, index) => (
                  <option key={index} value={school.name}>
                    {school.name}
                  </option>
                ))}
              </select>
              {errors.school && <div className="error">{errors.school}</div>}
            </div>

            <p className="popia-text">
              I confirm that all information provided by me on this app is my
              personal information. By continuing I agree to the terms and
              conditions of the Protection of Personal Information Act (POPIA).
            </p>
            <div className="button-container">
              <button
                className="main-button"
                type="button"
                onClick={validateStudentSignup}
              >
                Continue
              </button>
              <button
                className="ghost-button"
                type="button"
                onClick={() => setActiveForm("login")}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      );
    }
  };
  // return <div style={{width: "fit-content"}} hidden={!modalVisible}>{renderItem({ item: activeForm })}</div>;
  return (
    <>
      <button
        className={"back-button home-back ghost-button"}
        onClick={() => {
          activeForm === "login"
            ? (window.location.href =
                "https://careersbycolour.co.za/assessment/")
            : setActiveForm("login");
        }}
      >
        <Icon icon="lucide:arrow-left-circle" width="50" height="50" /> Back
      </button>
      <div className="assessment-modal">
        {modalVisible && renderItem({ item: activeForm })}
      </div>
    </>
  );
};

export default AssessmentAuthModal;
