/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
// import ReactHtmlParser from "react-html-parser";
import "./AssessmentSlide.css";
import { Icon } from "@iconify/react";

const AssessmentSlide = ({
  // data: { description, response },
  data,
  index,
  total,
  onButtonClick,
  onBackClick,
  active,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [localResponse, setLocalResponse] = useState(data.response);
  const getCurrentDimension = () =>{
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const setResponse = (value) => {
    setLocalResponse(value);
    onButtonClick(value);
  };

  useEffect(() => {
    const updateDimension = () => {
        setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);


    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
}, [screenSize])


  return (
    <>
      <button className={"back-button ghost-button"} onClick={()=> onBackClick()}>
        <Icon icon="lucide:arrow-left-circle" width="50" height="50" /> Back
      </button>
      <div className="slide-container">
        <div className="slide-content-wrapper">
          <h1 className="question-heading">{`Question ${index} of ${total}`}</h1>
          {/* {ReactHtmlParser(data.description, { decodeEntities: true })} */}
          <p className="q-description">{data.description}</p>
          <div className="yes-no-container">
            {/*localResponse is used to change the  style of the button*/}
            <button
              className="yes-no-button no-b"
              disabled={!active}
              onClick={() => setResponse("0")}
              
            >
              {localResponse === "0" ? (
                <Icon style={{stroke: "1px"}}
                  icon="bi:hand-thumbs-down-fill"
                  width="100"
                  height="100"
                  color="red"
                />
              ) : (
                <Icon icon="bi:hand-thumbs-down" width="100" height="100" strokeWidth="1"/>
              )}
            </button>
            <button
              className="yes-no-button yes-b"
              disabled={!active}
              onClick={() => setResponse("1")}
              
            >
              {localResponse === "1" ? (
                <Icon icon="bi:hand-thumbs-up-fill" width="100" height="100" color="green"/>
              ) : (
                <Icon icon="bi:hand-thumbs-up" width="100" height="100" />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentSlide;


