/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState, createContext, useEffect } from "react";
import './LoadingContext.css'

export const LoadingContext = createContext();

export const LoadingContextProvider = (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingContext.Provider value={[loading, setLoading]}>
      {loading && (
        <div className="loader-wrapper">
          <div className="loader glow-effect">
            <img className="load-img" src={require('../assets/adaptive-icon.png')} alt="" />
            <svg className="glow-container">
                <circle cx="150" cy="150" r="90" strokeLinecap="round" className="glow-line"/>
                <circle cx="150" cy="150" r="90" strokeLinecap="round" className="glow-blur"/>
            </svg>
          </div>
        </div>
      )}
      {props.children}
    </LoadingContext.Provider>
  );
};
