import React, { useRef, useState, useContext, useEffect } from "react";
// import { sliderWidth, itemWidth } from "../../styles/CarouselSlideStyles";
import CategorySlide from "./components/CategorySlide";
import { UserContext } from "../../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Icon } from "@iconify/react";

import "./CategoryCarousel.css";

const CategoryCarousel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { results, resultIndex, signedUrlSponsorLogo } = location.state;
  const carouselRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(resultIndex);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [playVideo, setPlayVideo] = useState(true);
  const [user] = useContext(UserContext);
  const swiperEl = document.querySelector("swiper-container");
  const swiperElRef = useRef(null);

  ////////////////////////////////////Effects/////////////////////////////

  useEffect(() => {
    const handleFocusChange = () => {
      const isFocused = document.hasFocus();

      if (isFocused) {
        setPlayVideo(true);
      }
    };

    window.addEventListener("focus", handleFocusChange);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
  }, []);

  useEffect(() => {
    const handleSwiperProgress = (e) => {
      const [swiper] = e.detail;
      setActiveIndex(swiper.activeIndex);
    };

    swiperElRef.current.addEventListener(
      "swiperslidechange",
      handleSwiperProgress
    );
  }, []);

  ////////////////////////////////////Effects/////////////////////////////

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const renderItem = ({ result, index }) => (
    console.log("element", activeIndex, index),
    (
      <CategorySlide
        index={index}
        activeIndex={activeIndex}
        playVideo={playVideo}
        category={result.category.name}
        categorySub={result.category.subline}
        colour={result.category.colour}
        video={result.category.video}
        screenWidth={screenWidth}
        // onSlideChange={handleSlideChange}
      />
    )
  );

  return (
    <>
      <div className="background-layout"></div> 
      <div className="car-wrapper">
        {screenWidth > 450 ? (
          <button
            className={"back-button ghost-button"}
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icon icon="lucide:arrow-left-circle" width="50" height="50" /> Back
          </button>
        ) : (
          <button
            className={"back-button-mobile back-button ghost-button"}
            onClick={() => {
              navigate(-1);
            }}
          >
            <Icon icon="lucide:arrow-left-circle" width="50" height="50" /> Back
          </button>
        )}

        {screenWidth > 900 ? (
          <swiper-container
            ref={swiperElRef}
            slides-per-view="1"
            initial-slide={activeIndex}
            navigation="true"
          >
            {results?.map((result, index) => (
              <swiper-slide key={index}>
                {renderItem({ result, index })}
              </swiper-slide>
            ))}
          </swiper-container>
        ) : (
          <swiper-container
            ref={swiperElRef}
            slides-per-view="1"
            pagination={{ clickable: true }}
            initial-slide={activeIndex}
          >
            {results?.map((result, index) => (
              <swiper-slide key={index}>
                {renderItem({ result, index })}
              </swiper-slide>
            ))}
          </swiper-container>
        )}
        <div className="button-wrapper">
          <button
            className="main-button"
            onClick={() => {
              navigate("/careerpaths", {
                state: { career: results[activeIndex] },
              });
            }}
          >
            See the different careers
          </button>
        </div>
        <div className="logos">
          <img className="sponsor-logo" src={signedUrlSponsorLogo} alt="" />
          <img
            className="sponsor-logo"
            src={require("../../assets/cbcLogoFull.png")}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};

export default CategoryCarousel;
