import React, { useRef, useContext, useState, useEffect } from "react";
import { Icon } from "@iconify/react";

import { UserContext } from "../../../context/UserContext";

const RelatedCareerPaths = ({
  relatedCareerPaths,
  setModalVisible,
  setModalHeader,
  setModalDescription,
  colour,
}) => {
  const carouselRef = useRef(null);
  const numberOfSlides  = window.innerWidth > 900 ? '3' : 2
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperElRef = useRef(null);
  const swiperEl = document.querySelector("swiper-container");


  useEffect(() => {
    const handleSwiperProgress = (e) => {
      const [swiper] = e.detail;
      setActiveIndex(swiper.activeIndex);
    };

    swiperElRef.current.addEventListener(
      "swiperslidechange",
      handleSwiperProgress
    );
  }, []);


  // const itemWidth = !isTablet(user.deviceType) ? "100px" : "100px";
  // const textSize = !isTablet(user.deviceType) ? "sm" : "md";

  const handleRelatedCareerClick = (item) => {
    setModalHeader(item.name);
    setModalDescription(item.description);
    setModalVisible(true);
  };




  const gotoPreviousSlide = () => {
    swiperEl.swiper.slidePrev();

    console.log(activeIndex);
  };

  const gotoNextSlide = () => {
    swiperEl.swiper.slideNext();
    console.log(activeIndex);
  };

  // const textWithSoftHyphens = (text) => hyphenated(text);

  const renderItem = (item) => (
    <div className="related-item-wrapper">
      <div
        className="related-item"
        style={{ border: `4px solid ${colour}` }}
        onClick={() => handleRelatedCareerClick(item)}
      >
        <p size={"20px"} fontWeight="600" textAlign="center" color="white">
          {item?.name}
        </p>
      </div>
    </div>
  );

  return (
    <div className="rel-car-path" style={{cursor:'pointer'}}>
      <swiper-container
        ref={swiperElRef}
        slides-per-view= {numberOfSlides}
        // initial-slide={activeIndex}
        // navigation="true"
      >
        {relatedCareerPaths?.map((result, index) => {
          return <swiper-slide key={index}>{renderItem(result)}</swiper-slide>;
        })}
      </swiper-container>
      <div  className="swipe-buttons">
        <div  disabled={!!activeIndex === 0} onClick={()=> gotoPreviousSlide()}>
        <Icon style={{color:`${colour}`}} icon="lucide:arrow-left-circle" width="50" height="50" /> 

        </div>
        <div disabled={!!activeIndex === relatedCareerPaths.length } onClick={() => gotoNextSlide()}>

        <Icon disabled={!!activeIndex === relatedCareerPaths.length } style={{transform: "rotate(180deg)", color:`${colour}`}} icon="lucide:arrow-left-circle" width="50" height="50" /> 
        </div>
      </div>
    </div>

  );
};

export default RelatedCareerPaths;
