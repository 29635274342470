import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
import { Icon } from "@iconify/react";

import "./CategorySlide.css";

const CategorySlide = (props) => {
  const {
    index,
    activeIndex,
    playVideo,
    category,
    categorySub,
    colour,
    video,
    screenWidth,
    // onSlideChange,
  } = props;

  const videoRef = useRef(null);
  const [signedUrlVideo, setSignedUrlVideo] = useState(null);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [showOverlay, setShowOverLay] = useState(true);
  // const [isPreloading, setIsPreloading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    async function fetchAssets() {
      setSignedUrlVideo(await Storage.get(video));
    }
    fetchAssets();
  }, [video]);

  useEffect(() => {
    if (index !== activeIndex) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    } else if (index === activeIndex && playVideo && isVideoLoaded) {
      videoRef.current.play();
    }
  }, [activeIndex, index]);

  useEffect(() => {
    videoRef.current.addEventListener("loadeddata", handleVideoLoad);
  }, [activeIndex, index]);

  const handleVideoLoad = async () => {
    setIsVideoLoaded(true);
  };

  const handlePlay = () => {
    setShowOverLay(false);
  };

  const handlePlayClick = () =>{
    videoRef.current.play()
  }
  const handlePause = () => {
    setShowOverLay(true);
  };

  const handleError = (e) => {
    // eslint-disable-next-line no-console
    console.warn("Video error:", e);
    setIsError(true);
  };

  return (
    <div className="car-slide-wrapper">
      <div>
        <div className="slide-head-container" style={{ color: `${colour}` }}>
          <h1 className="slide-heading">{category}</h1>
          <p className="slide-sub-head" color="#fff">
            {categorySub}
          </p>
        </div>
      </div>
      <div>
        <div>
          <div>
            {/* { isPreloading && !isError && (<Spinner size="lg" color={colour} style={{ flex: 1, position: "absolute", top: "40%", left: "45%" }} />)} */}
            {isError && (
              <p
                style={{
                  flex: 1,
                  position: "absolute",
                  top: "40%",
                  left: "40%",
                }}
              >
                Video failed to load
              </p>
            )}

            {screenWidth > 650 ? (
              <div
                className="video-container"
                style={{ border: `${colour} 20px solid` }}
              >
                {/* {showOverlay && (
                  <div className="video-overlay" >
                    <Icon
                    onClick={handlePlayClick}
                      className="play-icon"
                      icon="zondicons:play-outline"
                      width="80"
                      height="80"
                    />
                  </div>
                )} */}
                <div
                  className="desktop-video"
                  style={{ border: `white 15px solid` }}
                >

                <video
                  ref={videoRef}
                  onPlay={handlePlay}
                  onPause={handlePause}
                  controlsList="nodownload"
                  controls
                  src={signedUrlVideo}
                  onError={(e) => {
                    handleError(e);
                  }}
                  />
                  </div>
              </div>
            ) : (
              <div
              className="video-container-mobile"
              // style={{ height: "fit-content" }}
              >
                {showOverlay && (
                  <div className="video-overlay-mobile" >
                    <Icon
                    onClick={handlePlayClick}
                      className="play-icon"
                      icon="zondicons:play-outline"
                      width="80"
                      height="80"
                    />
                  </div>
                )}
                <video
                  onLoadedData={handleVideoLoad}
                  ref={videoRef}
                  onPlay={handlePlay}
                  onPause={handlePause}
                  className="mobile-video"
                  // style={{ border: `white 15px solid` }}
                  // style={{ flex: 1, width: "50vw" }}
                  controls
                  src={signedUrlVideo}
                  // autoPlay
                  onError={(e) => {
                    handleError(e);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CategorySlide.propTypes = {
  category: PropTypes.string.isRequired,
  categorySub: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
};

export default CategorySlide;
