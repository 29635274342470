import React from "react";

const RelatedCareerPathModal = ({ modalVisible, setModalVisible, careerPath, description, colour }) => (
<>
  { modalVisible && <div className="car-modal" onClick={() =>  setModalVisible(false)}>
    <div className="modal-box" style={{ border: `8px solid ${colour}` }}>
  <h2 style={{ color: `${colour}`, marginBottom:0 }}>{careerPath}</h2>
  <div className="modal-text-container">
  <p className="modal-text">{description}</p>
  </div>
  <button className="main-button modal-button" onClick={() => setModalVisible(false)}>close</button>
  </div>
  </div>
}
</>
);

export default RelatedCareerPathModal;
