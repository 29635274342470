/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./AssessmentDescription.css";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

const AssessmentDescription = ({ data: { description }, onButtonClick }) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        className={"back-button home-back ghost-button"}
        onClick={() => {
          navigate("/");
        }}
      >
        <Icon icon="lucide:arrow-left-circle" width="50" height="50" /> Back
      </button>
      <div className="desc-container">
        <div className="desc-wrapper">
          <h1 className="desc-head">Assessment Description</h1>
          <p className="description-text">{ReactHtmlParser(description)}</p>
          <div className="desc-button-wrapper">
            <button
              onClick={onButtonClick}
              className={"main-button desc-button"}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentDescription;
